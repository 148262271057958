:root {
  --app-height: 100%;
  --old-color: #116493;
}

@font-face {
  font-family: "AUTHENTICSans-Condensed90";
  src: url("./AUTHENTICSans-Condensed-90.otf") format("opentype");
}

html,
body {
   padding: 0;
   margin: 0;
   overflow: hidden;
   width: 100vw;
   height: 100vh;
   height: var(--app-height);
   text-transform: uppercase;
}

.filtered {
  filter: url(#n0)
}

.filtered-1 {
  filter: url(#n1)
}

.arch-logo {
  position: absolute;
  max-width: 120px;
}

.MQWrap {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.Ticker {
  display: flex;
  justify-content: space-between;
  font-family: "AUTHENTICSans-Condensed90", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #222;
  margin-right: 20px;
}

@media only screen and (max-width: 900px) {
  .MQWrap {
    bottom: 10px;
    background: none;
  }
}

.marquee-container {
  height: 16px;
}

/* .marquee {
  justify-content: space-between;
} */

.overlay {
  /* --gradient-color: none !important;
  --gradient-width: none !important; */
}

@media only screen and (max-width: 900px) {
  .Ticker {
    top: 10px;
    background: none;
  }
}

.App {
  margin: 80px;
  height: calc(var(--app-height) - 160px);
  position: relative;
}

@media only screen and (max-width: 900px) {
  .App {
    margin: 40px;
    height: calc(var(--app-height) - 80px);
    /* height: calc(100vh - env(safe-area-inset-bottom) - 200px); */
  }
}


.TopBut {
  font-family: "AUTHENTICSans-Condensed90", sans-serif;
  bottom: 0px;
  left: calc(50% - 50px);
  width: 180px;
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #222;
  border: 1px solid #222;
  border-radius: 100px;
  padding-top: 9px;
  padding-bottom: 7px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: none;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .TopBut {
    display: none;
  }
}

.MT {
  position: absolute;
  top: calc(50% - 92px);
  left: 0px;
  font-family: "AUTHENTICSans-Condensed90", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  text-align: left;
  color: #222;
  margin-bottom: 0px;
  max-width: 400px;
}

.TR {
  float: right;
  font-family: "AUTHENTICSans-Condensed90", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 145%;
  text-align: right;
  color: #222;
  margin-top: 0px;
}

.BL {
  position: absolute;
  bottom: 0px;
  left: 0px;
  font-family: "AUTHENTICSans-Condensed90", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 145%;
  text-align: left;
  color: #222;
  margin-bottom: 0px;
}

.BR {
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-family: "AUTHENTICSans-Condensed90", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 145%;
  text-align: right;
  color: #222;
  margin-bottom: 0px;
}